<template>
  <div class="careersDetail">
    <!-- <PageTopBanner :data="careersTopBanner" /> -->
    <div class="section__common__container">
      <!-- 타이틀 -->
      <div>
        <div
          v-if="$screen.breakpoint === 'pc'"
          class="flex"
        >
          <div
            class="w-1/2 pr-8 text-left text-gray-700 bg-white section__title"
          >
            채용 중인 포지션
            <span>채용 중인 포지션</span>
          </div>
          <div class="w-1/2 h-12 text-right pt-18">
            <div
              class="flex ml-auto items-center w-[435px] h-[48px] border-[1px] rounded-[4px] border-gray-200 input-border"
            >
              <input
                v-model="keyword"
                class="
                  bg-transparent outline-none  h-[20px]
                  tracking-[-0.1px] leading-[1.25]
                  text-[16px] font-normal text-gray-800
                  w-full pl-4
                "
                placeholder="직무 검색하기"
                type="text"
                @input="getFilterList"
                @keydown.enter="getFilterList"
              >
              <i 
                class="icon-search text-[24px] cursor-pointer pr-4"
                @click="getFilterList"
              />
            </div>
          </div>
        </div>
        <div
          v-else
          class="flex"
        >
          <div class="pr-5 text-left section__title dark">
            채용 중인 포지션
            <span>채용 중인 포지션</span>
          </div>
        </div>
        
        <!-- <div
          v-if="$screen.breakpoint === 'pc'"
          class="absolute myResume versionB"
        >
          <router-link
            to="/recruit/sign-up"
            class="myResume__link"
          >
            <span>지원하기</span>
            <span class="myResume__arrow">
              <i class="xi-angle-right-min" />
            </span>
          </router-link>
        </div> -->
      </div>
      <!-- end of 타이틀 -->
      <BadgeCheckBox
        class="articles__badge"
        :data="filteredBadgeData"
        @getValue="getFilterValue"
      />
      <div
        v-if="$screen.breakpoint !== 'pc'"
      >
        <div class="w-full tablet:h-12 tablet:pb-10 text-right tablet:pt-7 mobile:h-[42px] mobile:pt-4 mobile:pb-14">
          <div
            ref="searchDiv"
            tabindex="1"
            class="flex ml-auto items-center w-full tablet:h-[48px] mobile:min-w-[343px] mobile:h-[42px] border-[1px] rounded-[4px] border-gray-200"
          >
            <label for="searchInput" />
            <input
              id="searchInput"
              v-model="keyword"
              class="
                bg-transparent outline-none h-[20px]
                tracking-[-0.1px] leading-[1.25]
                text-[16px] font-normal text-gray-800
                w-full pl-4
              "
              placeholder="직무 검색하기"
              type="text"
              @focus="setInputBorder"
              @blur="removeInputBorder"
              @input="getFilterList"
              @keydown.enter="getFilterList"
            >
            <i 
              class="icon-search text-[24px] cursor-pointer pr-4"
              @click="getFilterList"
            />
          </div>
        </div>
      </div>
      <!-- 직무리스트 -->
      <JobList
        :jobs="filteredJobList"
        :keyword="keyword"
        class="pc:mt-[16px] tablet:mt-[16px] mobile:mt-0"
      />
      <!-- <div class="careers__list--wrapper">
        <div
          v-for="item in filteredJobList"
          :key="item.id"
          class="relative flex items-center careers__list"
          @click="openJobDetailPage(item)"
        >
          <div class="careers__name">
            {{ item.alias }}<the-100-badge v-if="item.jobTypeId!==14 && item.jobTypeId!==15" />
          </div>
          <div class="careers__subText">
            {{ item.yearsType }} · {{ item.employType }}
          </div>
          <div
            v-if="badgeData.filter(data => data.id === item.jobTypeId)[0].label"
            class="careers__recruitType"
          >
            {{ badgeData.filter(data => data.id === item.jobTypeId)[0].label }}
          </div>
          <div class="swiper-button-next" />
        </div>
      </div> -->
      <!-- end of 직무리스트 -->
    </div>
    <!-- <Guide /> -->
  </div>
</template>

<script>
// import PageTopBanner from '../../layout/components/PageTopBanner.vue';
// import Guide from './components/Guide.vue';
import BadgeCheckBox from '../../layout/components/BadgeCheckBox.vue';
import { getJobs, getOpenJobs } from '@/api/job';
import { trackClick } from '@/api/trackClick';
import JobList from '@/components/careers/jobList.vue';
// import The100Badge from '../../layout/components/The100Badge.vue';

export default {
  components: {
    // PageTopBanner,
    // Guide,
    BadgeCheckBox,
    JobList
  },
  data() {
    return {
      jobTypeId: 0,
      showTopBanner: false,
      filterValue: 0,
      filteredJobList: [],
      careersTopBanner: {
        bg: require('@/assets/careers/careersPage__bannerBg.png'),
        lottie: require('@/assets/lottie/careerTopBanner.json'),
        lottieMobile: require('@/assets/lottie/careerTopBanner_m.json'),
      },
      originJobList: [],
      jobList: [],
      filteredBadgeData: [],
      keyword: '',
      badgeData: [
        {
          idx: 0,
          name: 'jobType',
          label: '인턴',
          value: 16,
          id: 16,
        },
        {
          idx: 1,
          name: 'jobType',
          label: '게임기획/시나리오',
          value: 1,
          id: 1,
        },
        {
          idx: 2,
          name: 'jobType',
          label: '아트/디자인',
          value: 4,
          id: 4,
        },
        {
          idx: 3,
          name: 'jobType',
          label: '프로그래밍',
          value: 6,
          id: 6,
        },
        {
          idx: 4,
          name: 'jobType',
          label: '사업/마케팅',
          value: 3,
          id: 3,
        },
        {
          idx: 5,
          name: 'jobType',
          label: '경영지원',
          value: 8,
          id: 8,
        },
        {
          idx: 6,
          name: 'jobType',
          label: '고객관리',
          value: 11,
          id: 11,
        },
        {
          idx: 7,
          name: 'jobType',
          label: '인사/조직문화',
          value: 12,
          id: 12,
        },
        {
          idx: 8,
          name: 'jobType',
          label: '비디오/미디어',
          value: 13,
          id: 13,
        },
        {
          idx: 9,
          name: 'jobType',
          label: '데이터 분석',
          value: 2,
          id: 2,
        },
        {
          idx: 10,
          name: 'jobType',
          label: '전문연구요원',
          value: 7,
          id: 7,
        },
        {
          idx: 11,
          name: 'jobType',
          label: '프리랜서',
          value: 14,
          id: 14,
        },
        {
          idx: 12,
          name: 'jobType',
          label: '관계사',
          value: 15,
          id: 15,
        },
        {
          idx: 13,
          name: 'jobType',
          label: 'REMOTE',
          value: 20,
          id: 20,
        },
        {
          idx: 14,
          name: 'jobType',
          label: '비웨일즈(관계사)',
          value: 21,
          id: 21,
        }
      ],
    };
    // badgeData: [
    //   {
    //     idx: 0,
    //     name: 'jobType',
    //     label: '게임기획/시나리오',
    //     value: 1,
    //     id: 1,
    //   },
    //   {
    //     idx: 1,
    //     name: 'jobType',
    //     label: '아트',
    //     value: 4,
    //     id: 4,
    //   },
    //   {
    //     idx: 2,
    //     name: 'jobType',
    //     label: '프로그래밍',
    //     value: 6,
    //     id: 6,
    //   },
    //   {
    //     idx: 3,
    //     name: 'jobType',
    //     label: '사업/마케팅',
    //     value: 3,
    //     id: 3,
    //   },
    //   {
    //     idx: 4,
    //     name: 'jobType',
    //     label: '고객관리',
    //     value: 11,
    //     id: 11,
    //   },
    //   {
    //     idx: 5,
    //     name: 'jobType',
    //     label: '인사/조직문화',
    //     value: 12,
    //     id: 12,
    //   },
    //   {
    //     idx: 6,
    //     name: 'jobType',
    //     label: '비디오/미디어',
    //     value: 13,
    //     id: 13,
    //   },
    //   {
    //     idx: 7,
    //     name: 'jobType',
    //     label: '데이터 분석',
    //     value: 2,
    //     id: 2,
    //   },
    //   {
    //     idx: 8,
    //     name: 'jobType',
    //     label: '전문연구요원',
    //     value: 7,
    //     id: 7,
    //   },
    //   {
    //     idx: 9,
    //     name: 'jobType',
    //     label: '인턴',
    //     value: 16,
    //     id: 16,
    //   },
    //   {
    //     idx: 10,
    //     name: 'jobType',
    //     label: '프리랜서',
    //     value: 14,
    //     id: 14,
    //   },
    //   {
    //     idx: 11,
    //     name: 'jobType',
    //     label: '관계사',
    //     value: 15,
    //     id: 15,
    //   },
    //   {
    //     idx: 12,
    //     name: 'jobType',
    //     label: 'REMOTE',
    //     value: 20,
    //     id: 20,
    //   },
    // ],
    // };
  },
  async created(){
    this.filterArr();
    const id = '0'; //String(this.$store.getters.jobTypeId);
    this.jobTypeId = id;
    const result = await getOpenJobs(); //get jobs
    this.originJobList = result.data.items;
    this.jobList = this.originJobList;
    if(id === '0'){
      await this.getAllJobs();
    }else{
      await this.getFilterValue(id);
    }
    if(this.$route.params.keyword){
      this.filteredJobList = this.filteredJobList.filter(j => j.name.includes(this.$route.params.keyword));
    }
  },
  methods: {
    getFilterValue(value) {
      this.$store.dispatch('app/setJobTypeId', String(value));
      this.filterValue = value;
      if(this.filterValue === 0){
        this.getAllJobs();
      }else{
        this.filteredJobList = this.jobList.filter(item => String(item.jobTypeId) === String(this.filterValue));
        this.filteredJobList.sort(this.sortKorean);
        this.filteredJobList.sort(this.sortType);
      }
    },
    getFilterList(e) {
      this.keyword = e.target.value;
      if (this.keyword === '') {
        this.getAllJobs();
      } else {
        this.filteredJobList = this.jobList.filter(item => item.alias.includes(this.keyword));
        this.filteredJobList.sort(this.sortKorean);
        this.filteredJobList.sort(this.sortType);
      }
    },
    async getAllJobs() {
      this.jobList = this.originJobList;
      this.jobList.sort(this.sortKorean);
      this.jobList.map(item => { // index에 따라서 순서 소팅
        const order = this.badgeData.find(type => String(type.id) === String(item.jobTypeId));
        item.order = order.idx;
      });
      this.jobList.sort(function(a, b) {
        return a.order - b.order;
      });
      this.filteredJobList = this.jobList;
    },
    async filterArr() {
      const result = await getJobs();
      const data = result.data.items;
      let openedJobTypeList = [];
      data.map(item => {
        openedJobTypeList.push(item.id);
      });
      const filteredArr = this.badgeData.filter(item => openedJobTypeList.indexOf(item.value) > -1);
      this.filteredBadgeData = filteredArr;
      const all = {
        name: 'jobType',
        label: 'ALL',
        value: 0,
        id: 0
      };
      this.filteredBadgeData.unshift(all);
      this.filteredBadgeData.forEach(item => {
        if(item.value===Number(this.jobTypeId)) item.checked = true;
      });
    },
    async openJobDetailPage(item) {
      await trackClick({
        type: 'openPositions',
        value: item.id
      });

      // dataLayer.push({
      //   event: 'clickVersionB',
      // });

      // dataLayer.push({
      //   event: 'clickJob',
      //   job: item.name,
      // });
      this.$router.push(`/careers/jobDetail/${item.id}`);
    },
    sortKorean(a, b){
      if( a.alias > b.alias ) return 1;
      if( b.alias > a.alias ) return -1;
      return 0;
    },
    sortType(a, b){
      if( a.employType < b.employType ) return 1;
      if( b.employType < a.employType ) return -1;
      return 0;
    },
    setInputBorder() {
      this.$refs.searchDiv.style.border = 'solid 1px #333333';
    },
    removeInputBorder() {
      this.$refs.searchDiv.style.border = '';
    }
  }
};
</script>

<style lang="scss">
:is(.pc){
  .careersDetail{
    .myResume{
      &::after{
        max-width: 100vw;
        width: 770%;
        right: 670%;
      }
    }
  }
  .careers__list{
    .swiper-button-next{
      width: 34px !important;
      height: 34px !important;
      right: 18px !important;
      top: 35px !important;
      &::after{
        font-size: 1rem !important;
        font-weight: bold;
      }
    }
  }
  .input-border:focus-within {
    border: solid 1px #333333;
  }
}
</style>
<style lang="scss" scoped>
.careers{
  &__list{
    margin-bottom: 1rem;
    height: 62px;
    // padding: 0px 52px;
    border-radius: 70px;
    box-shadow: 2px 3px 6px 3.5px #e4e4e4;
    background-color: #FFF;
    &--wrapper{
      min-height: 60vh;
      padding: 20px 0px 110px;
    }
    &:last-child{
      margin-bottom: 0;
    }
    &:hover{
      cursor: pointer;
      color: $primaryColor;
      .swiper-button-next{
        background-color: $primaryColor;
      }
      .swiper-button-next::after{
        color: #FFF;
      }
    }
  }
  &__type{
    width: 23%;
    position: relative;
    font-family: $fontfamilyBlackHan;
    // font-weight: 900;
    font-size: 28px;
    height: 20px;
    text-align: center;
    &::after{
      content: "|";
      position: absolute;
      right: 0;
      font-size: 1.25rem;
      font-weight: 600;
      color: #999;
      .careers__list:hover &{
        color: $primaryColor;
      }
    }
  }
  &__name{
    width: 45%;
    padding-left: 43px;
    font-size: 21px;
    font-weight: bold;
  }
  &__subText{
    width: 35%;
    color: #999;
    text-align: center;
    font-size: 1rem;
  }
  &__recruitType{
    width: 20%;
    color: #999;
    padding-right: 80px;
    text-align: right;
    font-size: 1rem;
  }
}
:is(.mobile, .tablet){
  .careers__list{
    height: rem(76);
    padding: 0 rem(38);
    border-radius: rem(60);
    margin-bottom: rem(14);
    box-shadow: 1px 1.5px 3px 1.75px #e4e4e4;
  }
  .careers__name{
    width: 90%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow:ellipsis;
    padding: 0;
    font-size: rem(26);
  }
  .careers__recruitType{
    display: none;
  }
  .careers__subText{
    display: none;
  }
  .careersDetail .swiper-button-next{
    width: rem(44) !important;
    height: rem(44) !important;
    right: 9px !important;
    top: 29px !important;
    background-color: $primaryColor !important;
  }
  .careersDetail .swiper-button-next::after{
    font-size: .6rem !important;
    top: 0.5px; left: 1px;
    color: #FFF !important;
  }
}
</style>
