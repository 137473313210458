<template>
  <!-- <div
    v-if="$screen.breakpoint === 'pc'"
    class="inputRadio"
  >
    <label
      v-for="item in data"
      :id="`badge__${item.id}`"
      :key="item.idx"
    >
      <input
        class="hide"
        type="radio"
        :checked="item.checked"
        :name="item.name"
        :value="item.value"
      >
      <span
        class="txtRadio"
        @click="sendValue(item.value)"
      >{{ item.label }}</span>
    </label>
  </div> -->
  <div>
    <swiper
      ref="badgeSwiper"
      class="inputRadio rightOverflow"
      :navigation="true"
      :options="swiperOption"
      @tap="onTap"
      @slideChange="onSlideChange"
    >
      <swiper-slide
        v-for="item in data"
        :id="item.id"
        :key="item.idx"
      >
        <label>
          <input
            class="hide"
            type="radio"
            :checked="item.checked"
            :name="item.name"
            :value="item.value"
          >
          <span
            class="txtRadio"
            @click="sendValue(item.value)"
          >{{ item.label }}</span>
        </label>
      </swiper-slide>
    </swiper>
    <div
      v-if="$screen.breakpoint === 'pc'"
      class="badgeSwiper__btn--wrapper"
    >
      <div
        slot="button-prev"
        class="swiper-button-prev black"
      />
      <div
        slot="button-next"
        class="swiper-button-next black"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'BadgeCheckBox',
  props: [ 'data' ],
  data() {
    return {
      value: '',
      swiperOption: {
        // loop: true,
        slidesPerView: 'auto',
        preventClicks: true,
        preventClicksPropagation: false,
        observer: true,
        observeParents: true,
        loop: false,
        freeMode: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
    };
  },
  mounted() {
    this.$refs.badgeSwiper.$el.classList.remove('leftOverflow');
  },
  methods: {
    sendValue(value) {
      this.$emit('getValue',value);
    },
    onTap: function(swiper){
      const clickIdx = swiper.clickedIndex;
      if(this.$screen.breakpoint==='pc'){
        if(clickIdx>swiper.slides.length/2){
          swiper.slideTo(clickIdx -2, 300);
        }else{
          swiper.slideTo(0, 300);
        }
      }else{
        swiper.slideTo(clickIdx -2, 300);
      }
      if(swiper.translate === 0){
        this.$refs.badgeSwiper.$el.classList.add('rightOverflow');
        this.$refs.badgeSwiper.$el.classList.remove('leftOverflow');
      }else{
        this.$refs.badgeSwiper.$el.classList.add('rightOverflow');
        this.$refs.badgeSwiper.$el.classList.add('leftOverflow');
      }
    },
    onSlideChange: function(swiper) {
      if (swiper.snapIndex === 0) {
        this.$refs.badgeSwiper.$el.classList.add('rightOverflow');
        this.$refs.badgeSwiper.$el.classList.remove('leftOverflow');
      }

      if (swiper.snapIndex > 0) {
        this.$refs.badgeSwiper.$el.classList.add('leftOverflow');
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.inputRadio{
    label{
      overflow: hidden;
      margin: 0 6px;
      display: inline-block;
      width: auto;
      box-sizing: border-box;
      text-align: center;
      border-radius: 26px;
      &:hover{
        cursor: pointer;
      }
      &:first-child{
        margin-left: 0 !important;
      }
    }
    .txtRadio{
      user-select: none;
      display: inline-block;
      padding: 12px 21px;
      background: #f5f5f5;
      text-align: center;
      color: #777777;
      font-size: 1.1rem;
      line-height: 1.2rem;
      font-weight: bold;
      width: 100%; height: 100%;
    }
    input:checked + .txtRadio{
        background: $primaryColor;
        color: #FFF;
    }
    input:not(:checked):hover + .txtRadio{
        background: #fff2e5;
        color: #ff7f00;
    }
}
.swiper-container{
  padding-bottom: 0 !important;
}
.inputRadio{
  position: relative;
  .swiper-slide{
    width: auto !important;
  }
  &.rightOverflow::after{
    content: '';
    display: block;
    position: absolute;
    height: 100%;
    width: 1.625rem;
    background-image: -webkit-linear-gradient(right, #ffffff, rgba(255, 255, 255, 0));
    background-image: linear-gradient(to left, #ffffff, rgba(255, 255, 255, 0));
    top: 0;
    right: 0;
    z-index: 9;
    pointer-events: none;
  }
  &.leftOverflow::before{
    content: '';
    display: block;
    position: absolute;
    height: 100%;
    width: 1.625rem;
    background-image: -webkit-linear-gradient(left, #ffffff, rgba(255, 255, 255, 0));
    background-image: linear-gradient(to right, #ffffff, rgba(255, 255, 255, 0));
    top: 0;
    left: 0;
    z-index: 9;
    pointer-events: none;
  }
}
:is(.tablet, .mobile) .inputRadio .txtRadio{
  padding: 8px 16px;
  font-size: 14px;
}
:is(.tablet, .mobile) .inputRadio label{
  margin: 0 rem(8);
  border-radius: 2rem;
  &:first-child{
      margin-left: 0;
  }
}
:is(.tablet, .mobile) .inputRadio .swiper-slide:last-child{
  padding-right: 1.625rem;
}
:is(.pc) .inputRadio .swiper-slide:last-child{
  padding-right: 3rem;
}

.badgeSwiper{
  &__btn--wrapper{
    position: relative;
    top: -23px;
    .swiper-button-prev, .swiper-button-next{
      top: 0 !important;
      bottom: 0 !important;
      width: 32px !important;
      height: 32px !important;
      &:hover {
        border-color: #ff7f00 !important;
        color: #ff7f00 !important;
      }
    }
    .swiper-button-prev{
      left: -48px !important;
      &::after{
        font-size: 18px !important;
      }
    }
    .swiper-button-next{
      right: -48px !important;
      &::after{
        font-size: 18px !important;
      }
    }
  }
}
</style>
