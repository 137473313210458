<template>
  <div>
    <!-- <PageTopBanner :data="careersTopBanner" /> -->
    <!-- <IntensiveRecruitTopBanner /> -->
    <VersionB />
    <Guide />
  </div>
</template>

<script>
// import PageTopBanner from '../../layout/components/PageTopBanner.vue';
// import JobBox from './components/JobBox.vue';
import Guide from './components/Guide.vue';
// import IntensiveRecruitTopBanner from './components/IntensiveRecruitTopBanner.vue';
import VersionB from './versionB.vue';

export default {
  name: 'JobListPage',
  components: {
    // PageTopBanner,
    // JobBox,
    Guide,
    VersionB,
    // IntensiveRecruitTopBanner
  },
  data() {
    return {
      careersTopBanner: {
        title: 'Careers',
      },
    };
  },
};
</script>

<style lang="scss" scoped>
</style>
